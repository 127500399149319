<template>
  <el-tabs v-model="activeName">
    <el-tab-pane label="短信配置" name="first">
      <Sms />
    </el-tab-pane>
    <el-tab-pane label="邮件配置" name="second">
      <Email />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import Email from '../email/Email.vue'
import Sms from '../sms/Sms.vue'

export default {
  name: 'SMTPSetting',
  components: {
    Sms,
    Email,
  },
  data() {
    return {
      activeName: 'first',
    }
  },
}
</script>
