<template>
    <div>
        <el-button  v-show="!hasCreate && savePerm" type="primary" @click="dialogShow" class="sms-btn">添加配置</el-button>
        <div v-if="hasCreate">
            <el-card class="sms-wrap">
                <el-descriptions title="短信配置信息">
                    <el-descriptions-item label="短信平台">{{typeObj[smsInfo.type]}}</el-descriptions-item>
                    <el-descriptions-item label="授权id">{{smsInfo.accessKeySecret}}</el-descriptions-item>
                    <el-descriptions-item label="授权key">>{{smsInfo.accessKey}}</el-descriptions-item>
                    <el-descriptions-item label="应用id">>{{smsInfo.templateCode}}</el-descriptions-item>
                    <el-descriptions-item label="地域">>{{smsInfo.region}}</el-descriptions-item>
                </el-descriptions>
                <el-button v-show="savePerm" type="primary" @click="dialogShow" class="sms-btn">更新配置</el-button>
            </el-card>
            <h4 class="mt20">短信模板列表</h4>
            <el-table
                    v-loading="loading"
                    fit
                    highlight-current-row
                    :data="tableData"
                    stripe
                    style="width: 100%"
            >
                <el-table-column
                        prop="title"
                        label="记录id"
                />
                <el-table-column
                        prop="typeName"
                        label="模板id"
                />
                <el-table-column prop="status" label="模板内容" />
                <el-table-column prop="status" label="备注" />
                <el-table-column prop="status" label="签名" />
                <el-table-column prop="status" label="状态" />
                <el-table-column
                        label="操作"
                        width="200px"
                >
                    <template slot-scope="{row}">
                        <el-link
                                class="mr10"
                                type="primary"
                                @click="del(row.id)"
                        >
                            移除
                        </el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-empty v-else description="暂无配置"></el-empty>
        <SmsSave ref="smsSave" @handleFilter="getSmsSettings"/>
    </div>

</template>

<script>
 import SmsSave from '@/views/system/sms/SmsSave'
 import { GetSmsSettings,DeleteSmsSettings } from '@/api/system/system'
 import { error, findBtnPerm, success } from '@core/utils/utils'
  export default {
    name: 'Sms',
    components: { SmsSave },
    created() {
      this.getSmsSettings()
    },
    data() {
      return {
        savePerm: findBtnPerm('添加短信配置'),
        loading: false,
        tableData: [],
        hasCreate: false,
        typeObj: {
          1: '阿里云短信',
          2: '腾讯云短信',
        },
        smsInfo: {
          accessKey: '',
          accessKeySecret: '',
          region: '',
          templateCode: '',
          type: '',
          id: ''
        }
      }
    },
    methods: {
      dialogShow() {
        this.$refs.smsSave.dialogVisible = true
      },
      getSmsSettings() {
        GetSmsSettings().then(res=>{
          const resData = res.data
          if (resData.code === 0) {
            this.hasCreate = true
            this.smsInfo = resData.data
          } else {
            this.hasCreate = false
          }
        })
      },
      del(id) {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false,
        }).then(() => {
          DeleteSmsSettings({ id: id }).then(res => {
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.handleFilter()
            } else {
              error(resData.msg)
            }
          })
        })
      },
    }
  }
</script>

<style>
.sms-wrap{
    position: relative;
}
.sms-btn{
    position: absolute;
    top: 20px;
    right: 20px;
}
</style>
