<template>
    <el-dialog
            :title=" form.id ? '编辑' : '新增'"
            :visible.sync="dialogVisible"
            width="620px"
            @close="dialogClose"
    >
        <el-form
                v-if="dialogVisible"
                ref="form"
                v-loading="loading"
                :model="form"

                label-width="120px"
        >
            <el-form-item
                    label="短信平台"
                    prop="type"
                    :rules="[ { required: true, message: '短信平台不能为空', trigger: 'change' }]"
            >
               <el-select v-model="form.type">
                   <el-option v-for="(item,index) in typeOptions" :key="index" :value="item.value" :label="item.label"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item
                    label="授权id"
                    prop="accessKeySecret"
                    :rules="[ { required: true, message: '授权id不能为空', trigger: 'change' }]"
            >
                <el-input
                        v-model="form.accessKeySecret"
                        placeholder="请输入授权id"
                />
            </el-form-item>
            <el-form-item
                    label="授权key"
                    prop="accessKey"
                    :rules="[ { required: true, message: '授权key能为空', trigger: 'change' }]"
            >
                <el-input
                        v-model="form.accessKey"
                        placeholder="请输入授权key"
                />
            </el-form-item>
            <el-form-item
                    label="应用id"
                    prop="templateCode"
                    :rules="[ { required: true, message: '授权key能为空', trigger: 'change' }]"
            >
                <el-input
                        v-model="form.templateCode"
                        placeholder="请输入应用id"
                />
            </el-form-item>
            <el-form-item
                    label="地域"
                    prop="region"
                    :rules="[ { required: true, message: '地域不能为空', trigger: 'change' }]"
            >
                <el-input
                        v-model="form.region"
                        placeholder="请输入地域"
                />
            </el-form-item>
        </el-form>
        <div
                slot="footer"
                class="dialog-footer"
        >
            <el-button @click="dialogVisible = false">
                取 消
            </el-button>
            <el-button
                    type="primary"
                    :disabled="loading"
                    @click="submitForm"
            >
                提 交
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
  import { saveOrUpdateSms } from '@/api/system/system'
  import { success, error } from '@core/utils/utils'

  export default {
    data() {
      return {
        typeOptions: [
          {label: '阿里云短信 ', value: 1},
          {label: '腾讯云短信 ', value: 2},
        ],
        users: [],
        loading: false,
        dialogVisible: false,
        form: {
          accessKey: '',
          accessKeySecret: '',
          region: '',
          templateCode: '',
          type: '',
          id: '',
        },
      }
    },
    methods: {
      dialogClose() {
        this.form = {
          accessKey: '',
          accessKeySecret: '',
          region: '',
          templateCode: '',
          type: '',
          id: '',
        }
        this.$refs.form.resetFields()
      },
      // eslint-disable-next-line vue/no-dupe-keys
      submitForm() {
        // eslint-disable-next-line consistent-return
        this.$refs.form.validate(valid => {
          // eslint-disable-next-line no-empty
          if (valid) {
            this.loading = true
            var submitData = {
              accessKey: this.form.accessKey,
              accessKeySecret: this.form.accessKey,
              region: this.form.accessKey,
              templateCode: this.form.accessKey,
              type: this.form.type,
            }
            if(this.form.id){
              submitData.id = this.form.id
            }
            saveOrUpdateSms(submitData).then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.$emit('handleFilter')
              } else {
                error(resData.msg)
              }
            })
          } else {
            return false
          }
        })
      },
    },
  }
</script>
