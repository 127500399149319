<template>
    <el-dialog
            :title=" form.id ? '编辑' : '新增'"
            :visible.sync="dialogVisible"
            width="620px"
            @close="dialogClose"
    >
        <el-form
                v-if="dialogVisible"
                ref="form"
                v-loading="loading"
                :model="form"

                label-width="120px"
        >
            <el-form-item
                    label="SMTP地址"
                    prop="smtpHost"
                    :rules="[ { required: true, message: 'SMTP地址不能为空', trigger: 'change' }]"
            >
                <el-input
                        v-model="form.smtpHost"
                        placeholder="请输入SMTP地址"
                />
            </el-form-item>
            <el-form-item
                    label="SMTP端口"
                    prop="smtpPort"
                    :rules="[ { required: true, message: 'SMTP端口不能为空', trigger: 'change' }]"
            >
                <el-input
                        type="number"
                        v-model="form.smtpPort"
                        placeholder="请输入SMTP端口"
                />
            </el-form-item>
            <el-form-item
                    label="答复邮箱"
                    prop="answerEmail"
                    :rules="[ { required: true, message: '答复邮箱不能为空', trigger: 'change' }]"
            >
                <el-input
                        v-model="form.answerEmail"
                        placeholder="请输入答复邮箱"
                />
            </el-form-item>
            <el-form-item
                    label="SMTP账户"
                    prop="smtpAccount"
                    :rules="[ { required: true, message: 'SMTP账户不能为空', trigger: 'change' }]"
            >
                <el-input
                        v-model="form.smtpAccount"
                        placeholder="请输入SMTP账户"
                />
            </el-form-item>
            <el-form-item
                    label="SMTP密码"
                    prop="smtpPassword"
                    :rules="[ { required: true, message: 'SMTP密码不能为空', trigger: 'change' }]"
            >
                <el-input
                        v-model="form.smtpPassword"
                        placeholder="请输入SMTP密码"
                />
            </el-form-item>
            <el-form-item
                    label="是否加密"
            >
                <el-radio v-model="form.isSsl" :label="0">不加密</el-radio>
                <el-radio v-model="form.isSsl" :label="1">加密</el-radio>
            </el-form-item>
        </el-form>
        <div
                slot="footer"
                class="dialog-footer"
        >
            <el-button @click="dialogVisible = false">
                取 消
            </el-button>
            <el-button
                    type="primary"
                    :disabled="loading"
                    @click="submitForm"
            >
                提 交
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
  import { saveOrUpdateEmail } from '@/api/system/system'
  import { success, error } from '@core/utils/utils'

  export default {
    data() {
      return {
        loading: false,
        dialogVisible: false,
        form: {
          smtpHost: '',
          smtpPort: '',
          answerEmail: '',
          smtpAccount: '',
          smtpPassword: '',
          isSsl: 0,
          id: '',
        },
      }
    },
    methods: {
      dialogClose() {
        this.form = {
          smtpHost: '',
          smtpPort: '',
          answerEmail: '',
          smtpAccount: '',
          smtpPassword: '',
          isSsl: 0,
          id: '',
        }
        this.$refs.form.resetFields()
      },
      // eslint-disable-next-line vue/no-dupe-keys
      submitForm() {
        // eslint-disable-next-line consistent-return
        this.$refs.form.validate(valid => {
          // eslint-disable-next-line no-empty
          if (valid) {
            this.loading = true
            var submitData = {
              smtpHost: this.form.smtpHost,
              smtpPort: this.form.smtpPort,
              answerEmail: this.form.answerEmail,
              smtpAccount: this.form.smtpAccount,
              smtpPassword: this.form.smtpPassword,
              isSsl: this.form.isSsl,
            }
            if(this.form.id){
              submitData.id = this.form.id
            }
            saveOrUpdateEmail(submitData).then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.$emit('handleFilter')
              } else {
                error(resData.msg)
              }
            })
          } else {
            return false
          }
        })
      },
    },
  }
</script>
